/* eslint-disable @typescript-eslint/no-shadow */
import { action, observable } from 'mobx';
import {
	Model, _async, _await, idProp, modelFlow, prop,
} from 'mobx-keystone';

import { type MagicOverlayStore } from '~/components/magic-overlay/Stores/MagicOverlay.store';
import { IFormSettings } from '~/util/formz/Interfaces/FormSettings.interface';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { ERROR_KEY } from '~/util/messaging/promise-error/error.constants';
import { MagicOverlayStoreFactory } from '~/components/magic-overlay/Stores/MagicOverlay.store';
import { rnbFetch } from '~/util/rnbFetch.util';
import logger from '~/app/logger';

export interface FormSubmitRejectParams {
	error: any;
	statusCode: number;
	formModel: 	any;
	store?: any;
}

type FetchError = any;

type FormSubmitResolve = (response: Response) => void;

type FormSubmitReject = (params: FormSubmitRejectParams) => void;

export type SubmitHandler = (form: FormBuilder) => Promise<Response>;

export type PromiseHandler = (promise: Promise<Response>) => Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export abstract class AbstractFormStore<T> extends Model(<T>() => ({
	id: idProp.withSetter(),
	hasSaveError: prop(false),
	isLoading: prop<boolean | undefined>().withSetter(),
}))<T> {
	@observable.ref fetchError: FetchError;

	@observable.ref form!: any;

	@observable.ref startOverAction: Function | undefined;

	@observable.ref formSubmitResolve?: FormSubmitResolve;

	@observable.ref formSubmitReject?: FormSubmitReject;

	magicOverlayStore: MagicOverlayStore | null = null;

	onInit() {
		this.form = new FormBuilder(this.formModel, this.formSettings);
		this.magicOverlayStore = MagicOverlayStoreFactory.create({
			position: 'STICKY',
			manualMode: true,
		});
	}

	abstract get formModel(): T

	abstract get formSettings(): IFormSettings

	abstract get payload(): string

	abstract get saveUrl(): RequestInfo | URL

	get saveMethod() {
		return 'POST';
	}

	get allowSubmit() {
		try {
			return (
				!this.form.plugins.formValidator.hasErrors
				&& !this.form.plugins.formValidator.hasOnlyServerErrors
			);
		} catch (_) {
			return false;
		}
	}

	private isFetchError(data: unknown): data is { errors: unknown[] } {
		if (!data || typeof data !== 'object') {
			return false;
		}
		return Boolean(
			'errors' in data
			&& Array.isArray(data.errors)
			&& data.errors.length,
		);
	}

	@modelFlow
	promiseHandler = _async(function* (this: AbstractFormStore<T>, promise: Promise<Response>) {
		try {
			const response = yield* _await(promise);
			if (!response.ok && response.status !== 404) {
				const data = yield* (_await((response.json())));

				// TODO (aboyer) Figure out what shape or type the fetch error is.
				if (this.isFetchError(data)) {
					const {
						errors: [errorObject] = [],
					} = data;

					this.setFetchError(errorObject);
				}

				if (typeof this.formSubmitReject === 'function') {
					const rejectParams: FormSubmitRejectParams = {
						error: typeof this.fetchError !== 'undefined' ? this.fetchError : data,
						statusCode: response.status,
						formModel: this.form.model,
						store: this,
					};
					this.formSubmitReject(rejectParams);
				}
			} else if (response.status === 404) {
				this.setFetchError({ messageKey: ERROR_KEY.DEFAULT });
				if (typeof this.formSubmitReject === 'function') {
					const rejectParams: FormSubmitRejectParams = {
						error: this.fetchError,
						statusCode: response.status,
						formModel: this.form.model,
						store: this,
					};
					this.formSubmitReject(rejectParams);
				}
			} else if (typeof this.formSubmitResolve === 'function') {
				this.formSubmitResolve(response);
			}
		} catch (error: unknown) {
			this.setFetchError({ messageKey: ERROR_KEY.DEFAULT });
			logger.error(error);
		} finally {
			this.magicOverlayStore?.stopLoading?.();
			this.setIsLoading(false);
		}
	});

	@action.bound
	setFetchError(fetchError?: FetchError) {
		this.fetchError = fetchError;
	}

	setFormSubmitResolve(fn: FormSubmitResolve) {
		this.formSubmitResolve = fn;
	}

	setFormSubmitReject(fn: FormSubmitReject) {
		this.formSubmitReject = fn;
	}

	submitHandler(form: FormBuilder): Promise<Response> {
		this.setFetchError();
		form.plugins.formValidator.validateForm();
		if (form.plugins.formValidator.hasErrors) {
			return Promise.reject('Form invalid.');
		}

		if (this.saveUrl === '') {
			return Promise.reject('No save url found');
		}

		if (this.saveUrl === 'cancelled') {
			return Promise.resolve(new Response());
		}

		this.setIsLoading(true);
		this.magicOverlayStore?.startLoading?.();

		return rnbFetch(this.saveUrl, {
			requestHeaders: {
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/json',
			},
			options: {
				method: this.saveMethod,
				body: this.payload,
			},
		});
	}
}
