import React from 'react';

import { FeaturedCategories } from '~/components/featured-categories/FeaturedCategories';
import { FeaturedCategoriesModel } from '~/components/featured-categories/Models/FeaturedCategories.model';

export const STORAGE_OUTDOOR = () => {
	const model = new FeaturedCategoriesModel({
		featuredCategories: {
			categories: [
				{
					imgSrc: 'storage_outdoor_01',
					imgDimensions: [2528, 1580],
					link: '/catalog/storage-and-entryway',
					text: 'Storage',
				},
				{
					imgSrc: 'storage_outdoor_02',
					imgDimensions: [1248, 1580],
					link: '/catalog/outdoor',
					text: 'Outdoor',
					imgNudgeVerticalXS: -100,
				},
			],
			headerLevel: 'h2',
		}
	});

	return (
		<FeaturedCategories model={model} />
	);
};
