export enum ALIGN {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
}

export enum DIRECTION {
	NEXT = 'NEXT',
	PREV = 'PREV',
}

export enum EVENTS {
	AFTER_RESIZE = 'afterResize',
	CHANGED = 'changed',
	REACH_EDGE = 'reachEdge',
	READY = 'ready',
	VISIBLE_CHANGE = 'visibleChange',
	WILL_CHANGE = 'willChange',
}

export enum INPUT_TYPE {
	MOUSE = 'mouse',
	POINTER = 'pointer',
	TOUCH = 'touch',
}

export enum MOVE_TYPE {
	FREE_SCROLL = 'freeScroll',
	SNAP = 'snap',
	STRICT = 'strict',
}
