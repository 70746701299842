import type { ComponentClass, FunctionComponent } from 'react';

import { ERROR_KEY } from '~/util/messaging/promise-error/error.constants';
import { ResourceLockedMessage } from '~/util/messaging/promise-error/ResourceLockedMessage';
import { StartOverMessage } from '~/util/messaging/promise-error/StartOverMessage';
import { DefaultPromiseErrorMessage } from '~/util/messaging/promise-error/DefaultPromiseErrorMessage';
import { AccountNotFoundMessage } from '~/util/messaging/promise-error/AccountNotFoundMessage';
import { UsernameUniqueConstraintErrorMessage } from '~/util/messaging/promise-error/UsernameUniqueConstraintErrorMessage';
import { GiftCardTemporarilyBlockedMessage } from '~/util/messaging/promise-error/GiftCardTemporarilyBlockedMessage';
import { AvsAttemptFailedMessage } from '~/util/messaging/promise-error/AvsAttemptFailedMessage';
import { MaxAvsAttemptsReachedMessage } from '~/util/messaging/promise-error/MaxAvsAttemptsReachedMessage';
import { DuplicateGiftCardPaymentMessage } from '~/util/messaging/promise-error/DuplicateGiftCardPaymentMessage';
import { InvalidGiftCardNumberOrPinMessage } from '~/util/messaging/promise-error/InvalidGiftCardNumberOrPinMessage';
import { GiftCardVendorTimeoutMessage } from '~/util/messaging/promise-error/GiftCardVendorTimeoutMessage';
import { WebCreditCardDeclinedErrorMessage } from '~/util/messaging/promise-error/WebCreditCardDeclinedErrorMessage';
import { WebCreditCardExpiredErrorMessage } from '~/util/messaging/promise-error/WebCreditCardExpiredErrorMessage';
import { FinancialTransactionErrorMessage } from '~/util/messaging/promise-error/FinancialTransactionErrorMessage';
import { GiftCardAlreadyRedeemedMessage } from '~/util/messaging/promise-error/GiftCardAlreadyRedeemedMessage';
import { InsufficientFundsOnGiftCardMessage } from '~/util/messaging/promise-error/InsufficientFundsOnGiftCardMessage';
import { PaymentsExceedAmountDueMessage } from '~/util/messaging/promise-error/PaymentsExceedAmountDueMessage';
import { OrderConfirmationFailureMessage } from '~/util/messaging/promise-error/OrderConfirmationFailureMessage';
import { SharepointConnectionErrorMessage } from '~/util/messaging/promise-error/SharepointConnectionErrorMessage';
import { FinancialTransactionDeclinedMessage } from '~/util/messaging/promise-error/FinancialTransactionDeclinedMessage';
import { FinancialTransactionWithTokenDeclinedMessage } from '~/util/messaging/promise-error/FinancialTransactionWithTokenDeclinedMessage';
import { FinancialTransactionSapConnectionErrorMessage } from '~/util/messaging/promise-error/FinancialTransactionSapConnectionErrorMessage';
import { AccountExistsCheckoutErrorMessage } from '~/util/messaging/promise-error/AccountExistsCheckoutErrorMessage';
import { FinancialTransactionContactlessRemovedTooSoonMessage } from '~/util/messaging/promise-error/FinancialTransactionContactlessRemovedTooSoonMessage';
import { GiftCardInvalidProductErrorMessage } from '~/util/messaging/promise-error/GiftCardInvalidProductErrorMessage';
import { FinancialTransactionCancelledMessage } from '~/util/messaging/promise-error/FinancialTransactionCancelledMessage';
import { FinancialTransactionTimedOutMessage } from '~/util/messaging/promise-error/FinancialTransactionTimedOutMessage';
import { FinancialTransactionTerminalBusyMessage } from '~/util/messaging/promise-error/FinancialTransactionTerminalBusyMessage';
import { AccountExistsErrorMessage } from '~/util/messaging/promise-error/AccountExistsErrorMessage';
import { InvalidEmailErrorMessage } from '~/util/messaging/promise-error/InvalidEmailErrorMessage';
import { DuplicateQuoteContactErrorMessage } from '~/util/messaging/promise-error/DuplicateQuoteContactErrorMessage';
import { DuplicateContactErrorMessage } from '~/util/messaging/promise-error/DuplicateContactErrorMessage';
import { InvalidGiftCardNumberMessage } from '~/util/messaging/promise-error/InvalidGiftCardNumberMessage';
import { BlockedCustomerErrorMessage } from '~/util/messaging/promise-error/BlockedCustomerErrorMessage';
import { DeliveryAddressRestrictedByInventoryMessage } from '~/util/messaging/promise-error/DeliveryAddressRestrictedByInventoryMessage';
import { FinancialTransactionInvalidCardInformationMessage } from '~/util/messaging/promise-error/FinancialTransactionInvalidCardInformation';
import { PlacedOrderNotFoundMessage } from '~/util/messaging/promise-error/PlacedOrderNotFoundMessage';
import { FinanceActionRequiredMessage } from '~/util/messaging/promise-error/FinanceActionRequiredMessage';
import { AffirmActionRequiredMessage } from '~/util/messaging/promise-error/AffirmActionRequiredMessage';
import { RoutingErrorMessage } from '~/util/messaging/promise-error/RoutingErrorMessage';
import { UsernameOrPasswordNotFoundMessage } from '~/util/messaging/promise-error/UsernameOrPasswordNotFoundMessage';
import { LineItemSidemarkingExistsError } from '~/util/messaging/promise-error/LineItemSidemarkingExistsError';
import { TransportationZoneForbiddenMessage } from '~/util/messaging/promise-error/TransportationZoneForbiddenMessage';
import { NonDeliverableCertificateOfInsuranceErrorMessage } from '~/util/messaging/promise-error/NonDeliverableCertificateOfInsuranceErrorMessage';
import { IllegalCreateQuotePricingConditionErrorMessage } from '~/util/messaging/promise-error/IllegalCreateQuotePricingConditionErrorMessage';
import { IllegalCreateQuoteItemsErrorMessage } from '~/util/messaging/promise-error/IllegalCreateQuoteItemsErrorMessage';

type PromiseErrorMessagesType = {
	[index in ERROR_KEY]: ComponentClass<any, any> | FunctionComponent<any>
}
export const PromiseErrorMessages: PromiseErrorMessagesType = {
	[ERROR_KEY.DEFAULT]: DefaultPromiseErrorMessage,
	[ERROR_KEY.ACCOUNT_EXISTS_CHECKOUT_ERROR]: AccountExistsCheckoutErrorMessage,
	[ERROR_KEY.ACCOUNT_EXISTS_SET_UP_ONLINE_ACCESS_ERROR]: UsernameUniqueConstraintErrorMessage,
	[ERROR_KEY.ACCOUNT_EXISTS_ERROR]: AccountExistsErrorMessage,
	[ERROR_KEY.AFFIRM_ACTION_REQUIRED]: AffirmActionRequiredMessage,
	[ERROR_KEY.AVS_ATTEMPT_FAILED]: AvsAttemptFailedMessage,
	[ERROR_KEY.BLOCKED_CUSTOMER_ERROR]: BlockedCustomerErrorMessage,
	[ERROR_KEY.DELIVERY_ADDRESS_RESTRICTED_BY_INVENTORY]: DeliveryAddressRestrictedByInventoryMessage,
	[ERROR_KEY.DUPLICATE_GIFT_CARD_PAYMENT]: DuplicateGiftCardPaymentMessage,
	[ERROR_KEY.DUPLICATE_QUOTE_CONTACT]: DuplicateQuoteContactErrorMessage,
	[ERROR_KEY.DUPLICATE_CONTACT]: DuplicateContactErrorMessage,
	[ERROR_KEY.EMAIL_OR_PASSWORD_NOT_FOUND]: AccountNotFoundMessage,
	[ERROR_KEY.FINANCE_ACTION_REQUIRED]: FinanceActionRequiredMessage,
	[ERROR_KEY.FINANCIAL_TRANSACTION_CANCELLED]: FinancialTransactionCancelledMessage,
	[ERROR_KEY.FINANCIAL_TRANSACTION_CONTACTLESS_REMOVED_TOO_SOON]: FinancialTransactionContactlessRemovedTooSoonMessage,
	[ERROR_KEY.FINANCIAL_TRANSACTION_DECLINED]: FinancialTransactionDeclinedMessage,
	[ERROR_KEY.FINANCIAL_TRANSACTION_ERROR]: FinancialTransactionErrorMessage,
	[ERROR_KEY.FINANCIAL_TRANSACTION_INVALID_CARD_INFORMATION]: FinancialTransactionInvalidCardInformationMessage,
	[ERROR_KEY.FINANCIAL_TRANSACTION_SAP_CONNECTION_ERROR]: FinancialTransactionSapConnectionErrorMessage,
	[ERROR_KEY.FINANCIAL_TRANSACTION_TERMINAL_BUSY]: FinancialTransactionTerminalBusyMessage,
	[ERROR_KEY.FINANCIAL_TRANSACTION_TIMED_OUT]: FinancialTransactionTimedOutMessage,
	[ERROR_KEY.FINANCIAL_TRANSACTION_WITH_TOKEN_DECLINED]: FinancialTransactionWithTokenDeclinedMessage,
	[ERROR_KEY.GIFT_CARD_ALREADY_REDEEMED]: GiftCardAlreadyRedeemedMessage,
	[ERROR_KEY.GIFT_CARD_INVALID_PRODUCT_ERROR]: GiftCardInvalidProductErrorMessage,
	[ERROR_KEY.GIFT_CARD_TEMPORARILY_BLOCKED]: GiftCardTemporarilyBlockedMessage,
	[ERROR_KEY.GIFT_CARD_VENDOR_TIMEOUT]: GiftCardVendorTimeoutMessage,
	[ERROR_KEY.HOT_PAYMENTS_ERROR]: DefaultPromiseErrorMessage,
	[ERROR_KEY.HOT_REFUNDS_ERROR]: DefaultPromiseErrorMessage,
	[ERROR_KEY.ILLEGAL_CREATE_QUOTE_ITEMS_ERROR]: IllegalCreateQuoteItemsErrorMessage,
	[ERROR_KEY.ILLEGAL_CREATE_QUOTE_PRICING_CONDITION_ERROR]: IllegalCreateQuotePricingConditionErrorMessage,
	[ERROR_KEY.INSUFFICIENT_FUNDS_ON_GIFT_CARD]: InsufficientFundsOnGiftCardMessage,
	[ERROR_KEY.INSUFFICIENT_PAYMENT_ERROR]: DefaultPromiseErrorMessage,
	[ERROR_KEY.INVALID_EMAIL_ERROR]: InvalidEmailErrorMessage,
	[ERROR_KEY.INVALID_GIFT_CARD_NUMBER]: InvalidGiftCardNumberMessage,
	[ERROR_KEY.INVALID_GIFT_CARD_NUMBER_OR_PIN]: InvalidGiftCardNumberOrPinMessage,
	[ERROR_KEY.LINE_ITEM_SIDEMARKING_EXISTS_ERROR]: LineItemSidemarkingExistsError,
	[ERROR_KEY.MAX_AVS_ATTEMPTS_REACHED]: MaxAvsAttemptsReachedMessage,
	[ERROR_KEY.NON_DELIVERABLE_CERTIFICATE_OF_INSURANCE_ERROR]: NonDeliverableCertificateOfInsuranceErrorMessage,
	[ERROR_KEY.OPTIMISTIC_LOCK_FAILURE]: StartOverMessage,
	[ERROR_KEY.ORDER_CONFIRMATION_FAILURE]: OrderConfirmationFailureMessage,
	[ERROR_KEY.ORDER_EDIT_PATCH_FAILED_NEW_ORDER_CREATED_ERROR_KEY]: DefaultPromiseErrorMessage,
	[ERROR_KEY.PAYMENTS_EXCEED_AMOUNT_DUE]: PaymentsExceedAmountDueMessage,
	[ERROR_KEY.PLACED_ORDER_NOT_FOUND]: PlacedOrderNotFoundMessage,
	[ERROR_KEY.REFUND_REQUIRES_FINANCE]: FinanceActionRequiredMessage,
	[ERROR_KEY.RESOURCE_LOCKED]: ResourceLockedMessage,
	[ERROR_KEY.RESOURCE_LOCKED_BY_USER]: ResourceLockedMessage,
	[ERROR_KEY.ROUTING_ERROR]: RoutingErrorMessage,
	[ERROR_KEY.SALESFORCE_INVALID_EMAIL]: InvalidEmailErrorMessage,
	[ERROR_KEY.SAP_CONNECTION_ERROR]: DefaultPromiseErrorMessage,
	[ERROR_KEY.SHAREPOINT_CONNECTION_ERROR]: SharepointConnectionErrorMessage,
	[ERROR_KEY.SHAREPOINT_AUTH_ERROR]: SharepointConnectionErrorMessage,
	[ERROR_KEY.TRANSPORTATION_ZONE_FORBIDDEN]: TransportationZoneForbiddenMessage,
	[ERROR_KEY.USERNAME_OR_PASSWORD]: AccountNotFoundMessage,
	[ERROR_KEY.USERNAME_OR_PASSWORD_NOT_FOUND]: UsernameOrPasswordNotFoundMessage,
	[ERROR_KEY.USERNAME_UNIQUE_CONSTRAINT_ERROR]: UsernameUniqueConstraintErrorMessage,
	[ERROR_KEY.WEB_CREDIT_CARD_DECLINED_ERROR]: WebCreditCardDeclinedErrorMessage,
	[ERROR_KEY.WEB_CREDIT_CARD_EXPIRED_ERROR]: WebCreditCardExpiredErrorMessage,
};
