import { DINING_LIVING } from '~/components/featured-categories/Components/DINING_LIVING';
import { DINING_BEDROOM } from '~/components/featured-categories/Components/DINING_BEDROOM';
import { OUTDOOR_LIVING } from '~/components/featured-categories/Components/OUTDOOR_LIVING';
import { STORAGE_OUTDOOR } from '~/components/featured-categories/Components/STORAGE_OUTDOOR';

export const FeatCatsLibrary = {
	'DINING_BEDROOM': DINING_BEDROOM,
	'DINING_LIVING': DINING_LIVING,
	'OUTDOOR_LIVING': OUTDOOR_LIVING,
	'STORAGE_OUTDOOR': STORAGE_OUTDOOR,
};
