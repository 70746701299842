import { isLogLevelEnabled, logger } from '~/app/logger';
import { apiUrl } from '~/global/global.constants';

export type Options = Partial<{
	requestHeaders: HeadersInit
	options: Omit<RequestInit, 'headers'>
}>;

export const rnbFetch = async (
	url: RequestInfo | URL,
	params: Options = {
		options: { method: 'get', credentials: 'include' },
	},
) => {
	const apiRequestUrl = `${apiUrl}/${url}`.replace('//api', '/api');
	const ts = performance.now();
	const {
		requestHeaders: headers,
		options,
	} = params;
	const response = await fetch(apiRequestUrl, {
		method: 'get',
		credentials: 'include',
		headers,
		...options,
	});

	if (isLogLevelEnabled('info')) {
		const measure = performance.now() - ts;
		logger.info(`rnbFetch: ${apiRequestUrl} - ${Math.round(measure)}ms`);
	}

	return response;
};
