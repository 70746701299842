export enum HERO_ID {
    BAYLOR_VIDEO = 'BAYLOR_VIDEO',
    CLEARANCE = 'CLEARANCE',
    CLEARANCE_NOV = 'CLEARANCE_NOV',
    DINING = 'DINING',
    ELY = 'ELY',
    FRESH_FINDS = 'FRESH_FINDS',
    LIVING = 'LIVING',
    NEW_ARRIVALS = 'NEW_ARRIVALS',
    NEW_ARRIVALS_JAN25 = 'NEW_ARRIVALS_JAN25',
    OUTDOOR = 'OUTDOOR',
}
