import dayjs from 'dayjs';

// This needs to be relative to appease Jest.
// eslint-disable-next-line import/no-relative-packages
import validate from '../../../node_modules/validate.js';

import * as customValidators from '~/lib/validatejs/customValidators';

validate.options = {
	format: 'detailed',
};

validate.extend(validate.validators.datetime, {
	parse: (value, options) => {
		return options.withDateOnly ? dayjs(value).startOf('day').valueOf() : dayjs(value).valueOf();
	},
	format: (value, options) => {
		// Custom option, `withDateOnly`. The built-in option, `dateOnly`, does not work.
		const format = options.withDateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';

		return dayjs(value).format(format);
	},
});

Object.entries(customValidators).forEach((entry) => {
	const [name, validator] = entry;

	validate.validators[name] = validator;
});

export default validate; // eslint-disable-line import/no-default-export
