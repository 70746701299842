import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { SelectorDetails } from '~/product/common/selector/Components/SelectorDetails';
import { SelectorValueSwatch } from '~/product/common/selector/Components/swatch/SelectorValueSwatch';
import { getShouldShowShadowY } from '~/product/common/selector/Utils/Selector.utils';

import styles from '#/product/standard/selector/selector-swatch.module.scss';
import stylesSelector from '#/product/standard/selector/selector.module.scss';

export const SelectorSwatch = observer(({
	isActive = false,
	selectedSelector: {
		groupValuesByStocked = false,
		label = '',
		selectedSelectorValue: {
			hasSelectorDetails,
			selectorDetailImage,
			selectorDetailsToRender = [],
		} = {},
		selectorValuesToRender = [],
		spoSelectorValues = [],
		stockedSelectorValues = [],
		showSelectorDetails,
	} = {},
	selectorIndex = 0,
}) => {
	// these values MUST match what you have in CSS, and I hate it a whole bunch
	// nested flexbox columns don't grow their parent width, so we need to figure it out on our own...
	const selectorValueMargin = 4;

	const selectorValueWidth = 78;

	const spoSelectorValuesContainerWidth = spoSelectorValues.length * selectorValueWidth + (spoSelectorValues.length - 1) * selectorValueMargin;

	const stockedSelectorValuesContainerWidth = stockedSelectorValues.length * selectorValueWidth + (stockedSelectorValues.length - 1) * selectorValueMargin;

	const scrollingParentRef = useRef();

	const scrollingChildRef = useRef();

	const [showShadow, setShowShadow] = useState(false);

	let ticking = false;

	function setShowShadowState() {
		const shouldShowShadow = getShouldShowShadowY({
			isActive,
			scrollingChildRef,
			scrollingParentRef,
		});

		setShowShadow(shouldShowShadow);
	}

	function handleScroll() {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				ticking = false;

				setShowShadowState();
			});

			ticking = true;
		}
	}

	useEffect(setShowShadowState, [isActive]);

	return (
		<>
			{
				showSelectorDetails && hasSelectorDetails && (
					<SelectorDetails
						isActive={isActive}
						selectorDetailImage={selectorDetailImage}
						selectorDetails={selectorDetailsToRender}
					/>
				)
			}
			<div
				className={`${styles['selector-swatch-container']}`}
				onScroll={handleScroll}
				ref={scrollingParentRef}
			>
				<div
					className={
						classNames('selector-swatch', {
							[styles['selector-swatch-shadow']]: showShadow,
						})
					}
					ref={scrollingChildRef}
				>
					<fieldset className="selector-values-types-swatch-container">
						<div className={styles['selector-values-types-swatch']} data-qa="selector-values-types-swatch">
							<legend className="tw-sr-only">Choose a {label}</legend>
							{
								!groupValuesByStocked && (
									<div className={styles['selector-values-type-swatch']}>
										<div className={styles['selector-values-swatch']}>
											{
												selectorValuesToRender.map((selectorValue, index) => {
													return (
														<SelectorValueSwatch
															isActive={isActive}
															key={`selector-value-${index}`}
															label={label}
															selectorIndex={selectorIndex}
															selectorValue={selectorValue}
														/>
													);
												})
											}
										</div>
									</div>
								)
							}
							{
								groupValuesByStocked && stockedSelectorValues.length > 0 && (
									<div
										className={`${styles['selector-values-type-swatch']} selector-values-type-swatch-stocked`}
										style={{ flexBasis: stockedSelectorValuesContainerWidth }}
									>
										<span className={styles['selector-values-type-swatch-title']}>
											<span className="tw-pr-3">Stocked</span>
											<span className={stylesSelector['selector-heading-count']} data-qa="selector-values-type-swatch-title-stock-option-count">{stockedSelectorValues.length} {stockedSelectorValues.length === 1 ? 'option' : 'options'}</span>
										</span>
										<div className={styles['selector-values-swatch']}>
											{
												stockedSelectorValues.map((selectorValue, index) => {
													return (
														<SelectorValueSwatch
															isActive={isActive}
															key={`selector-value-${index}`}
															label={label}
															selectorIndex={selectorIndex}
															selectorValue={selectorValue}
														/>
													);
												})
											}
										</div>
									</div>
								)
							}
							{
								groupValuesByStocked && spoSelectorValues.length > 0 && (
									<div
										className={`${styles['selector-values-type-swatch']} selector-values-type-swatch-spo`}
										style={{ flexBasis: spoSelectorValuesContainerWidth }}
									>
										<span className={styles['selector-values-type-swatch-title']}>
											<span className="tw-pr-3">Made for you</span>
											<span className={stylesSelector['selector-heading-count']} data-qa="selector-values-type-swatch-title-spot-option-count">{spoSelectorValues.length} {spoSelectorValues.length === 1 ? 'option' : 'options'}</span>
										</span>
										<div className={styles['selector-values-swatch']}>
											{
												spoSelectorValues.map((selectorValue, index) => {
													return (
														<SelectorValueSwatch
															isActive={isActive}
															key={`selector-value-${index}`}
															label={label}
															selectorIndex={selectorIndex}
															selectorValue={selectorValue}
														/>
													);
												})
											}
										</div>
									</div>
								)
							}
						</div>
					</fieldset>
				</div>
			</div>
		</>
	);
});
