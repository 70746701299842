import React from 'react';
import axios, { AxiosResponse } from 'axios';
import {
	idProp, Model, model, prop, modelAction, modelFlow, _async, _await,
} from 'mobx-keystone';
import { computed, observable } from 'mobx';

import type { IAddToFavoritesResponse } from '~/favorites/Types/Favorites.interface';
import { apiUrl } from '~/global/global.constants';
import { modelNamespace } from '~/util/modelNamespace';
import { FavoritesListSummary } from '~/favorites/Models/FavoritesListSummary.model';
import { CreateNewFavoritesListStore } from '~/favorites/create-new-list/Stores/CreateNewList.store';
import { CreateNewFavoritesList } from '~/favorites/create-new-list/Components/CreateNewFavoritesList';
import { createFavoriteSummaries } from '~/favorites/list/Factories/FavoritesSummary.factory';
import { SelectAFavoriteList } from '~/product/common/interstitial/favorites/Components/SelectAFavoritesList';
import { IFavorites } from '~/favorites/Types/Favorites.interface';
import { FavoritesTrackingEventsStore } from '~/favorites/Stores/FavoritesEvents.tracking.store';
import { FeatureTogglesModel } from '~/util/feature-toggles/Models/FeatureToggles.model';
import { createAddedProductGroup } from '~/favorites/list/Factories/AddedToFavorites.factory';
import { AddedProductGroup } from '~/favorites/add/Models/AddedProductGroup.model';

@model(`${modelNamespace.FAVORITES}/AddToFavoritesStore`)
export class AddToFavoritesStore extends Model({
	id: idProp,
	addToFavoritesAPI: prop<string>('/api/favorites/add-standard'),
	lists: prop<FavoritesListSummary[]>(() => []),
	productDTO: prop<any | null>(),
	productModalInfo: prop<any | null>(),
	targetFavoriteListID: prop<string | null>(null).withSetter(),
	targetFavoritePhotosListIds: prop<string[] | undefined>().withSetter(),
	trackingEventsStore: prop<FavoritesTrackingEventsStore | undefined>().withSetter()
}) {
	@observable formModel: any;

	magicModal: any;

	@observable.ref featureTogglesModel: FeatureTogglesModel | undefined;

	successCallback: Function | undefined;

	failureCallback: Function | undefined;

	@observable isProcessing: boolean | undefined;

	@observable.ref saveError: any;

	@observable.ref sumAdded!: number;

	@observable.ref addedProductGroups: AddedProductGroup[] | undefined;

	onInit() {
		this.formModel = {};
		this.resetForm();
		this.isProcessing = true;
		this.sumAdded = 0;
	}

	@computed
	get isSaveDisabled() {
		return this.listIdsPayload.length === 0;
	}

	@modelAction
	resetForm() {
		Object.keys(this.formModel).forEach(key => delete this.formModel[key]);

		this.lists.forEach((list) => {
			this.formModel[list.id] = false;
		});
	}

	@modelAction
	updateSelectedState(id: string, isChecked: boolean) {
		if (id in this.formModel) {
			this.formModel[id] = isChecked;
		}
	}

	@modelAction
	setLists(lists: FavoritesListSummary[]) {
		this.lists = lists;
		this.resetForm();
	}

	@computed
	get listIdsPayload() {
		return Object.keys(this.formModel).map((key) => {
			if (this.formModel[key]) {
				return key;
			}
			return null;
		}).filter(Boolean);
	}

	clearSaveError() {
		this.saveError = undefined;
	}

	setSaveError(error: any) {
		this.saveError = error;
	}

	@modelFlow
	submitHandler = _async(function* (this: AddToFavoritesStore) {
		this.isProcessing = true;
		try {
			const promise = axios
				.post(`${apiUrl}${this.addToFavoritesAPI}`, {
					targetFavoritesListIds: this.listIdsPayload.length
						? this.listIdsPayload
						: [this.targetFavoriteListID],
					...this.productDTO,
				});
			const { data }: AxiosResponse<IAddToFavoritesResponse> = yield* _await(promise);
			const { analytics, addedProductGroups } = data;
			try {
				this.trackingEventsStore?.addToFavorites(
					this.listIdsPayload.length
						? this.listIdsPayload as string[]
						: [this.targetFavoriteListID] as string[],
					analytics.addedProducts
				);
			} catch (error: unknown) {
				console.error(error);
			}

			try {
				this.sumAdded = 0;
				this.addedProductGroups = addedProductGroups.map((group) => {
					const addedGroup = createAddedProductGroup(group);
					this.sumAdded += addedGroup.subTotal;
					return addedGroup;
				});
			} catch (error: unknown) {
				console.error(error);
			}

			if (this.successCallback) {
				this.successCallback();
			}
		} catch (error) {
			console.error(error);

			this.setSaveError(error);

			if (this.failureCallback) {
				this.failureCallback(error);
			}
		}

		this.isProcessing = false;
	});

	@modelFlow
	submitImageHandler = _async(function* (this: AddToFavoritesStore) {
		this.isProcessing = true;

		try {
			const promise = axios
				.post(`${apiUrl}${this.addToFavoritesAPI}`, {
					imageFilename: this.productDTO.imageFilename,
					targetFavoritePhotosListIds: this.targetFavoritePhotosListIds,
				});
			// const { data }: AxiosResponse<IAddToFavoritesResponse> = yield* _await(promise);
			yield* _await(promise);
			// const { analytics, addedProductGroups } = data;
			try {
				// Deal with favorites event tracking later
			} catch (error: unknown) {
				console.error(error);
			}
			if (this.successCallback) {
				this.successCallback();
			}
		} catch (error) {
			console.error(error);

			this.setSaveError(error);

			if (this.failureCallback) {
				this.failureCallback(error);
			}
		}

		this.isProcessing = false;
	});

	@modelFlow
	switchToCreateANewListModal = _async(function* (this: AddToFavoritesStore) {
		const {
			magicModal: {
				alterModal = () => { },
			} = {},
		} = this;

		try {
			const promise = this.openCreateANewListModal();
			const newListData = yield* _await(promise);

			if (typeof newListData !== 'undefined') {
				const lists = createFavoriteSummaries(newListData);
				this.setLists(lists);
				if (lists.length > 1) {
					this.updateSelectedState(lists[lists.length - 1].id, true);
				}
			}

			alterModal({
				id: 'added-to-wishlist-modal',
				title: 'Choose Your List(s)',
				maxWidth: '500px',
				closeModalOnOverlayClick: false,
				content: {
					children: (
						<SelectAFavoriteList
							store={this}
						/>
					),
				},
			});
		} catch (error) {
			if (this.failureCallback) {
				this.failureCallback('Failed to create new list');
			}
			console.error(error);
		}
	});

	@modelAction
	openCreateANewListModal() {
		const {
			magicModal,
			magicModal: {
				alterModal = () => { },
			} = {},
		} = this;

		const promise = new Promise<IFavorites | undefined>((resolve, reject) => {
			try {
				if (magicModal) {
					const newListStore = new CreateNewFavoritesListStore({
						createNewListAPI: '/api/favorites/add-list',
					});

					newListStore.featureTogglesModel = this.featureTogglesModel;
					newListStore.openModalResolve = resolve;
					newListStore.openModalReject = reject;

					alterModal({
						title: 'Create New List',
						maxWidth: 500,
						content: {
							children: (
								<CreateNewFavoritesList
									store={newListStore}
								/>
							)
						}
					});
				} else {
					reject('Magic Modal not found');
				}
			} catch (error) {
				console.error(error);
			}
		});

		return promise;
	}
}
