import React from 'react';
import classNames from 'classnames';

import { s7ContentPath } from '~/global/global.constants';

import styles from '~/components/Buttons/ButtonShopRoom/ButtonShopRoom.module.scss';
import shopRoomLinkStyles from '~/components/Buttons/Components/ShopRoomLink.module.scss';

export const ButtonShopRoom = (props) => {
	const {
		posAbsRight = null,
		posAbsLeft = null,
		buttonText = 'Shop',
		shopLink,
		dataQA = 'shop-room-button',
		trLinkEventCompName = null,
		trLinkEventCompType = null,
	} = props;

	return (
		<a
			className={classNames({
				[styles.shopThisRoomLink]: true,
				[shopRoomLinkStyles.root]: true,
				[styles['abs-right']]: posAbsRight,
				[styles['abs-left']]: posAbsLeft,
				'tw-flex': true,
			})}
			data-tr-link-event-comp-name={trLinkEventCompName}
			data-tr-link-event-comp-type={trLinkEventCompType}
			data-qa={dataQA}
			href={shopLink}
		>
			<img
				alt=""
				className={styles.shopIcon}
				src={`${s7ContentPath}/shopping-tag`}
			/>
			<span className={styles.shopThisRoomLinkText}>{buttonText}</span>
		</a>

	);
};
